import { BigNumber, Signer, ethers } from "ethers";
import { create } from "zustand";
import { Erc20 } from "../types/ethers-contracts/Erc20";
import { Erc20__factory } from "../types/ethers-contracts/factories/Erc20__factory";
import { rpc } from "../App";

const makeApprovalId = (
  chainId: number,
  token: string,
  operator: string,
  account: string
): string => {
  return chainId + "|" + token + "|" + operator + "|" + account;
};

const makeBalanceId = (
  chainId: number,
  token: string,
  account: string
): string => {
  return chainId + "|" + token + "|" + account;
};

const makeInstanceId = (chainId: number, token: string): string => {
  return chainId + "|" + token;
};

export type ERC20Template = {
  instances: Record<string, Erc20>;
  balances: Record<string, BigNumber>;
  status: Record<string, string>;
  approvals: Record<string, BigNumber>;
  observe: (
    chainId: number,
    address: string,
    account: string,
    signer: Signer
  ) => void;
  getApproval: (
    chainId: number,
    token: string,
    operator: string,
    account?: string
  ) => BigNumber;
  getBalance: (chainId: number, token: string, account?: string) => BigNumber;
  getInstance: (chainId: number, token: string, signer: Signer) => Erc20;
  fetchApproval: (
    chainId: number,
    token: string,
    operator: string,
    account: string,
    hook?: (allowance: BigNumber) => void
  ) => void;
  fetchBalance: (
    chainId: number,
    token: string,
    account: string,
    hook?: (allowance: BigNumber) => void
  ) => void;
};

export const useERC20 = create<ERC20Template>((set, store) => ({
  instances: {},
  balances: {},
  approvals: {},
  status: {},
  getInstance: (chainId, token, signer) => {
    console.log(chainId, token);
    const entry = store().instances[makeInstanceId(chainId, token)];
    if (typeof entry === "undefined") {
      return Erc20__factory.connect(token, signer);
    }
    return entry;
  },
  getApproval: (chainId, token, operator, account) => {
    if (typeof account === "undefined" || !token || !ethers.utils.isAddress(token)) {
      return BigNumber.from(0);
    }
    const approvalIndex = makeApprovalId(chainId, token, operator, account);
    const status = store().status[approvalIndex];
    if (typeof status === "undefined") {
      setTimeout(() => {
        if (store().status[approvalIndex] !== 'fetching') {
          store().fetchApproval(chainId, token, operator, account);
        }
      }, 1);
    }
    const entry = store().approvals[approvalIndex];
    return entry ? entry : BigNumber.from(0);
  },
  getBalance: (chainId, token, account) => {
    if (typeof account === "undefined" || !token || !ethers.utils.isAddress(token)) {
      return BigNumber.from(0);
    }
    const balanceId = makeBalanceId(chainId, token, account);
    const status = store().status[balanceId];
    if (typeof status === "undefined") {
      setTimeout(() => {
        store().fetchBalance(chainId, token, account);
      }, 1)
    }
    const entry = store().balances[balanceId];
    return typeof entry !== "undefined" ? entry : BigNumber.from(0);
  },
  fetchApproval: async (chainId, token, operator, account, hook) => {
    if (!token || !ethers.utils.isAddress(token)) {
      console.error("Invalid token address:", token);
      return;
    }
    const approvalIndex = makeApprovalId(chainId, token, operator, account);
    set({
      status: {
        ...store().status,
        [approvalIndex]: "fetching",
      },
    });
    const provider = new ethers.providers.JsonRpcProvider(rpc[chainId]);
    const erc20Instance = Erc20__factory.connect(token, provider);
    erc20Instance.allowance(account, operator).then((allowance) => {
      set({
        approvals: {
          ...store().approvals,
          [approvalIndex]: allowance,
        },
        status: {
          ...store().status,
          [approvalIndex]: "fetched",
        },
      });  
      hook && hook(allowance);
    }).catch((e) => {
      set({
        status: {
          ...store().status,
          [approvalIndex]: "error",
        },
      });
      console.log(e, "ApprovalError");
    });
  },
  fetchBalance: async (chainId, token, account, hook) => {
    console.log("Fetching",chainId,token,account,hook)
    if (!token || !ethers.utils.isAddress(token)) {
      console.error("Invalid token address:", token);
      return;
    }
    const balanceIndex = makeBalanceId(chainId, token, account);
    set({
      status: {
        ...store().status,
        [balanceIndex]: "fetching",
      },
    });
    const provider = new ethers.providers.JsonRpcProvider(rpc[chainId]);
    const erc20Instance = Erc20__factory.connect(token, provider);
    erc20Instance.balanceOf(account).then((balance) => {
      set({
        balances: {
          ...store().balances,
          [balanceIndex]: balance,
        },
        status: {
          ...store().status,
          [balanceIndex]: "fetched",
        },
      });
      hook && hook(balance);  
    }).catch((e) => {
      console.log(e, "BalanceError");
      set({
        status: {
          ...store().status,
          [balanceIndex]: "error",
        },
      });
    });
  },
  observe: (chainId, token, account, signer) => {
    if (!token || !ethers.utils.isAddress(token)) {
      console.error("Invalid token address:", token);
      return;
    }
    const erc20Instance = Erc20__factory.connect(token, signer);
    set({
      instances: {
        ...store().instances,
        [makeInstanceId(chainId, token)]: erc20Instance,
      },
    });
    erc20Instance.on(
      erc20Instance.filters["Transfer(address,address,uint256)"](
        null,
        account,
        null
      ),
      () => {
        store().fetchBalance(chainId, token, account);
      }
    );
    erc20Instance.on(
      erc20Instance.filters["Transfer(address,address,uint256)"](
        account,
        null,
        null
      ),
      () => {
        store().fetchBalance(chainId, token, account);
      }
    );
    erc20Instance.on(
      erc20Instance.filters["Approval(address,address,uint256)"](
        account,
        null,
        null
      ),
      (_, spender) => {
        store().fetchApproval(chainId, token, spender, account);
      }
    );
  },
}));