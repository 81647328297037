/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import { information, swapVertical } from "ionicons/icons";
import React from "react";
import { Redirect, Route } from "react-router-dom";
// import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { ethers } from "ethers";
import { supported } from "./hooks/useChainId";
import { BridgePage } from "./pages/Bridge";
import { Governance } from "./pages/Governance";
import { TreasuryPage } from "./pages/Treasury";
import "./theme/variables.css";

import {
  Arbitrum,
  ArbitrumGoerli,
  Avalanche,
  Base,
  Config,
  DAppProvider,
  Fantom,
  Goerli,
  Mainnet,
} from "@usedapp/core";
import { Modals } from "./components/Modals";
import { Core, Shimmer ,Scroll} from "./shimmer";

export const appConfig: { name: string; live: boolean; icon: string } = {
  name: "Surveyor DAO",
  icon: "/assets/survToken-surv-logo.jpeg",
  live: true,
};

export const explorers: Record<number, string> = {
  [Fantom.chainId]: "https://ftmscan.com/",
  [ArbitrumGoerli.chainId]: "https://goerli.arbiscan.io/",
  [Arbitrum.chainId]: "https://arbiscan.io/",
  [Avalanche.chainId]: "https://snowtrace.io/",
  [Shimmer.chainId]: "https://explorer.evm.shimmer.network/",
  [Core.chainId]: Core.blockExplorerUrl!,
  [Scroll.chainId]: Scroll.blockExplorerUrl!,
};
export const rpc: Record<number, string> = {
  [Fantom.chainId]: "https://fantom.publicnode.com",
  [Arbitrum.chainId]: "https://arbitrum-one.publicnode.com",
  [Avalanche.chainId]: "https://rpc.ankr.com/avalanche",
  [Base.chainId]: "https://base-rpc.publicnode.com",
  [Shimmer.chainId]: "https://json-rpc.evm.shimmer.network",
  [Core.chainId]: "https://rpc.coredao.org/",
  [Scroll.chainId]: Scroll.rpcUrl!,

};

export const features: Record<number, string[]> = {
  [Fantom.chainId]: ["bridge"],
  [Base.chainId]: ["bridge"],
  [Avalanche.chainId]: ["bridge"],
  [Shimmer.chainId]: ["bridge"],
  [Core.chainId]:["bridge"],
  [Scroll.chainId]:["bridge"]
};
export const supportedNetworks = supported;
export const blockStart: Record<number, number> = {
  [Fantom.chainId]: 65038975,
};

Fantom.rpcUrl = rpc[Fantom.chainId];
Goerli.rpcUrl = rpc[Goerli.chainId];
ArbitrumGoerli.rpcUrl = rpc[ArbitrumGoerli.chainId];
Arbitrum.rpcUrl = rpc[Arbitrum.chainId];
Base.rpcUrl = rpc[Base.chainId];
Base.multicall2Address="0xcA11bde05977b3631167028862bE2a173976CA11";
Mainnet.rpcUrl = rpc[Mainnet.chainId];
Avalanche.rpcUrl = rpc[Avalanche.chainId];
Shimmer.rpcUrl = rpc[Shimmer.chainId];
Core.rpcUrl = rpc[Core.chainId];
const headers = { "Content-Type": "application/json; charset=utf-8" };
export const dappConfig: Config = {
  readOnlyChainId: Fantom.chainId,
  networks: [Fantom, Avalanche, Base, Shimmer,Core,Scroll],
  readOnlyUrls: {
    [Fantom.chainId]: new ethers.providers.StaticJsonRpcProvider({
      url: Fantom.rpcUrl,
      headers,
    }),
    [Base.chainId]: new ethers.providers.StaticJsonRpcProvider({
      url: Base.rpcUrl,
      headers,
    }),
    [Avalanche.chainId]: new ethers.providers.StaticJsonRpcProvider({
      url: Avalanche.rpcUrl,
      headers,
    }),
    [Shimmer.chainId]: new ethers.providers.StaticJsonRpcProvider({
      url: Shimmer.rpcUrl!,
      headers,
    }),
    [Core.chainId]: new ethers.providers.StaticJsonRpcProvider({
      url: Core.rpcUrl!,
      headers,
    }),
    [Scroll.chainId]: new ethers.providers.StaticJsonRpcProvider({
      url: Scroll.rpcUrl!,
      headers,
    }),
  },
};

setupIonicReact({ mode: "md" });

// export const graphQLclient = new ApolloClient({
//   uri: 'https://api.thegraph.com/subgraphs/name/lilesper/zktge',
//   cache: new InMemoryCache(),
// });
const App: React.FC = () => {
  return (
    <IonApp>
      {/* <ApolloProvider client={graphQLclient}> */}
      <DAppProvider config={dappConfig}>
        <Modals />
        <IonReactHashRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/">
                <Redirect to="/bridge" />
              </Route>
              <Route exact path="/bridge">
                <BridgePage />
              </Route>
              <Route exact path="/treasury">
                <TreasuryPage />
              </Route>
              <Route exact path="/info">
                <Governance />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="bridge" href="/bridge">
                <IonIcon aria-hidden="true" icon={swapVertical} />
                <IonLabel>Bridge</IonLabel>
              </IonTabButton>
              {/* <IonTabButton tab="treasury" href="/treasury">
              <IonIcon aria-hidden="true" icon={logoBitcoin} />
              <IonLabel>Treasury</IonLabel>
            </IonTabButton> */}
              <IonTabButton tab="info" href="/info">
                <IonIcon aria-hidden="true" icon={information} />
                <IonLabel>Info</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactHashRouter>
      </DAppProvider>
      {/* </ApolloProvider> */}
    </IonApp>
  );
};

export default App;
