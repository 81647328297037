import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonIcon,
  IonItem,
  IonModal,
  IonText
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useRef, useState } from "react";
import { dappConfig, supportedNetworks } from "../App";
import { useChainId } from "../hooks/useChainId";
import { icons, names } from "./ChainSelector";

export const BridgeChainSelector: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { setBridgeChainId,bridgeChainId,chainId} = useChainId();
  const icon = bridgeChainId && icons[bridgeChainId];
  const name = bridgeChainId && names[bridgeChainId];
  const [open, setOpen] = useState<boolean>(false);
  return (
    <IonChip onClick={()=>{setOpen(true)}}>
        {icon&&<IonIcon icon={icon}/>}
<IonText>        {name}

</IonText>
      <IonModal
        ref={modal}
        isOpen={open}
        backdropDismiss={
          bridgeChainId != 0 &&
          typeof bridgeChainId !== "undefined" &&
          supportedNetworks.includes(bridgeChainId)
        }
        onWillDismiss={() => {
          setOpen(false);
        }}
      >
        <IonCard>
          <IonCardHeader color={"primary"}>
            <IonItem color="primary" lines="none">
              <IonCardTitle>Networks:</IonCardTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="clear"
                  onClick={() => {
                    modal.current?.dismiss();
                  }}
                >
                  <IonIcon icon={closeOutline} color="danger" />
                </IonButton>
              </IonButtons>
            </IonItem>
          </IonCardHeader>
          <IonCardContent>
            {dappConfig.networks
              ?.filter((x) => supportedNetworks.includes(x.chainId)).filter(x=>x.chainId!==chainId)
              .map((chain) => (
                <IonItem
                href="javascript:void(0)"
                  onClick={() => {
                    setBridgeChainId(chain.chainId);
                    modal.current?.dismiss();
                  }}
                  key={chain.chainId}
                >
                  <IonButtons slot="start">
                    <IonIcon icon={icons[chain.chainId]} />
                    {chain.chainId}
                  </IonButtons>
                  <IonText color={bridgeChainId===chain.chainId?"tertiary":undefined}>
                  {chain.chainName}
                  </IonText>
                  {bridgeChainId === chain.chainId && (
                    <IonChip color={"tertiary"}>
                      Selected
                    </IonChip>
                  )}
                </IonItem>
              ))}
          </IonCardContent>
        </IonCard>
      </IonModal>
    </IonChip>
  );
};
