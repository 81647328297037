import { ContractTransaction, Signer, ethers } from 'ethers'
import { create } from 'zustand'

interface TransactionInfo {
  transaction: ContractTransaction,
  name: string
  hook: () => void
}

export type TransactionTemplate = {
  transactions: TransactionInfo[]  
  confirmations: Record<string, number>
  clear: (id: string) => void
  hide: () => void
  show: () => void
  pending: () => number
  open: boolean
  post: (
    signer: Signer | { provider: ethers.providers.Provider },
    tx: ContractTransaction,
    name: string,
    hook: () => void | undefined,
  ) => void
}

export const useTransactionModal = create<TransactionTemplate>(
  (set, store) => ({
    transactions: [],
    pending: () => {
      return store().transactions.filter(x => !(x.transaction.hash && x.transaction.hash in store().confirmations)).length
    },
    confirmations: {},
    open: false,
    post: (signer, transaction, name, hook) => {
      set({ transactions: [...store().transactions, { hook, name, transaction }] })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      signer.provider!.waitForTransaction(transaction.hash, 1).then((data) => {
        hook && hook();
        set({ confirmations: { ...store().confirmations, [transaction.hash]: data.confirmations } })
      });    
    },
    clear: (hash) => {
      set({ transactions: store().transactions.filter((x) => x.transaction.hash !== hash) })
    },
    hide: () => {
      set({ open: false })
    },
    show: () => {
      set({ open: true })
    },
  }),
)