import {
  IonAvatar,
  IonBadge,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToggle,
} from "@ionic/react";

import { BigNumber } from "ethers";
import { walletOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { AppHeader } from "../components/AppHeader";
import useWindowDimensions from "../hooks/useWindowDimensions";
// eslint-disable-next-line @typescript-eslint/no-var-requires

interface AssetItem {
  amount: number;
  chain: string;
  decimals: number;
  display_symbol: string;
  id: string;
  is_core: boolean;
  is_verified: boolean;
  is_wallet: boolean;
  logo_url: string;
  name: string;
  optimized_symbol: string;
  price: number;
  protocol_id: string;
  symbol: string;
  time_at: number;
}
interface PortfolioItem {
  asset_dict: Record<string, number>;
  asset_token_list: AssetItem[];
  detail: {
    supply_token_list: AssetItem[];
  };
  detail_types: string[];
  name: string;
  pool: {
    adapter_id: string;
    chain: string;
    controller: string;
    id: string;
    index: string;
    project_id: string;
    time_at: number;
  };
  proxy_detail: null;
  stats: {
    asset_usd_value: number;
    debt_usd_value: number;
    net_usd_value: number;
  };
  update_at: number;
}

interface projectItem {
  chain: string;
  dao_id: string;
  has_supported_portfolio: boolean;
  id: string;
  is_tvl: boolean;
  is_visible_in_defi: boolean;
  logo_url: string;
  name: string;
  platform_token_id: string;
  portfolio_item_list: PortfolioItem[];
  site_url: string;
  tag_ids: string[];
  tvl: number;
}
interface dataItem {
  amount: number;
  balance: number;
  chain: string;
  credit_score: number;
  decimals: number;
  display_symbol: string;
  id: string;
  is_core: boolean;
  is_custom: boolean;
  is_scam: boolean;
  is_suspicious: boolean;
  is_verified: boolean;
  is_wallet: boolean;
  logo_url: string;
  name: string;
  optimized_symbol: string;
  price: number;
  protocol_id: string;
  symbol: string;
  time_at: number;
}
const options = {
  body: null,
  method: "GET",
  headers: {
    accept: "*/*",
    account:
      '{"random_at":1683585182,"random_id":"1dd6b4498af04b9882f6f9fd73138fd9","user_addr":"0xcc879ab4de63fc7be6aaca522285d6f5d816278e","wallet_type":"metamask"}',
    "accept-language": "en-US,en;q=0.9",
    "sec-ch-ua":
      '"Not.A/Brand";v="8", "Chromium";v="114", "Google Chrome";v="114"',
    "sec-ch-ua-mobile": "?1",
    "sec-ch-ua-platform": '"Android"',
    "sec-fetch-dest": "empty",
    "sec-fetch-mode": "cors",
    "sec-fetch-site": "same-site",
    source: "web",
    "x-api-ver": "v2",
    Referer: "https://debank.com/",
    Origin: "https://debank.com/",
    "x-api-ts": "1689547860",
    "x-api-nonce": "n_aehMaId0GU2akqb2ZLvU2sOLku3IETgcPREz5kdL",
    "x-api-sign":
      "d9b9cef6115ff75143b9af4b26bb97a0b00acc15f71340b59f284cbae52f8cf",
    "Referrer-Policy": "strict-origin-when-cross-origin",
  },
};
async function retryFetch(
  url: string,
  options: RequestInit = {},
  retries = 20,
  backoff = 1000
): Promise<Response> {
  try {
    const response = await fetch(url, options);
    // 429 is the status code for Too Many Requests (Rate Limiting)
    if (response.status === 429 && retries > 0) {
      // Wait for the backoff period before retrying
      await new Promise((res) => setTimeout(res, backoff));
      // Double the backoff period for the next retry
      return retryFetch(url, options, retries - 1, backoff * 2);
    } else if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    } else {
      return response;
    }
  } catch (err) {
    if (retries > 0) {
      // Wait for the backoff period before retrying
      await new Promise((res) => setTimeout(res, backoff));
      // Double the backoff period for the next retry
      return retryFetch(url, options, retries - 1, backoff * 2);
    } else {
      throw err;
    }
  }
}
const bigNumberStringifier = {
  test: (value: any) => BigNumber.isBigNumber(value),
  stringify: (value: BigNumber) => value.toHexString(),
};

const backupLogos: Record<string, string> = {
  "0x208234F4f8B1bBEA59cfDc38666141654e851DCe":
    "https://items.farmgod.finance/static/media/candle.3b77f2bb.jpg_small",
    "0x89ca18732db4a24e175dd1c79cd06245265cc42a":
    "https://magepunks.xyz/assets/images/potion-icon.png",
    "0x3eda36088b931098e8e472748840b3df78268c72":
    "https://magepunks.xyz/assets/images/potion-icon.png",
  };
const getBackupLogo = (id: string) => {
  return backupLogos[id] || "";
};

function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
const max_retry = 32;
export const TreasuryPage: React.FC = () => {
  const address = "0xebb8ee4722501358bf70559d26ef6e7b1326b3c6";

  const [dataItems, setDataItems] = useState<Record<string, dataItem[]>>({});
  const [hideSmallHoldings, setHideSmallHoldings] = useState<boolean>(true);
  const [debank, setDebank] = useState<string>();
  const [stats, setStats] = useState<{ desc: { usd_value: number } }>({
    desc: { usd_value: -1 },
  });
  const [projectItems, setProjecItems] = useState<
    Record<string, projectItem[]>
  >({});
  useEffect(() => {
    console.log("USED EFFECT");
    const fetch_cached_balances = () => {
      console.log("Getting Cached Balances");
      return new Promise<void>((resolve, reject) => {
        retryFetch(
          "https://api.debank.com/token/cache_balance_list?user_addr=" +
            address,
          options
        )
          .then(async (response) => {
            const info = await response.json();
            setDataItems((x) => ({ ...x, [address]: info.data }));
            resolve();
          })
          .catch(reject);
      });
    };
    const getProjects = async () => {
      console.log("Getting Projects");
      return new Promise<void>((resolve, reject) => {
        retryFetch(
          "https://api.debank.com/portfolio/project_list?user_addr=" + address,
          options
        )
          .then(async (response) => {
            const info = await response.json();
            setProjecItems((x) => ({ ...x, [address]: info.data }));
            resolve();
          })
          .catch(reject);
      });
    };
    const getStats = async () => {
      return new Promise<void>((resolve, reject) => {
        retryFetch("https://api.debank.com/user/addr?addr=" + address, options)
          .then(async (response) => {
            const info = await response.json();
            setStats((x) => info.data);
            resolve();
          })
          .catch(reject);
      });
    };
    (async () => {
      await getStats();
      await new Promise((res) => setTimeout(res, 1000));
      await fetch_cached_balances();
      await new Promise((res) => setTimeout(res, 2000));
      await getProjects();
      console.log("Fully loaded");
    })();
  }, []);
  const { width } = useWindowDimensions();
  const smol = width <= 576;
  const dataItemEntries = Object.entries(dataItems);
  const itemHeader = (
    <IonItem>
      <IonCol sizeSm="3" sizeXs="4">
        <IonCardTitle>Token</IonCardTitle>
      </IonCol>
      {!smol && (
        <IonCol sizeSm="3" sizeXs="0">
          <IonCardTitle>Price</IonCardTitle>
        </IonCol>
      )}
      <IonCol sizeSm="3" sizeXs="4">
        <IonCardTitle>Amount</IonCardTitle>
      </IonCol>
      <IonCol sizeSm="3" sizeXs="4">
        <IonCardTitle>Value</IonCardTitle>
      </IonCol>
    </IonItem>
  );

  return (
    <IonPage>
      <AppHeader title={"Treasury"} />
      <IonContent>
        {stats.desc.usd_value === -1 && (
          <IonItem target="_new" href={"https://debank.com/profile/" + address}>
            <IonText color="primary">View on debank</IonText>
          </IonItem>
        )}
        <IonItem>
          <IonButtons slot="start">
            <IonTitle>Treasury value</IonTitle>
          </IonButtons>
          <IonButtons slot="end">
            {stats.desc.usd_value === -1 ? (
              <IonSpinner name="dots" />
            ) : (
              <IonText color="success">
                ${stats.desc.usd_value.toFixed(2)}
              </IonText>
            )}
          </IonButtons>
        </IonItem>
        {dataItemEntries.length === 0 && (
          <IonItem className="ion-text-center">
            <IonSpinner name="dots" />
          </IonItem>
        )}
        {dataItemEntries.map(([chain, data], i) => (
          <IonCard key={i}>
            <IonCardHeader color="light">
              <IonRow>
                <IonCol size="1">
                  <IonIcon
                    color="secondary"
                    size="large"
                    icon={walletOutline}
                  />
                </IonCol>
                <IonCol size="9">
                  <IonCardTitle> Token Holdings </IonCardTitle>
                  <IonCardSubtitle></IonCardSubtitle>
                </IonCol>
                <IonCol size="2">
                  <IonBadge color="light">
                    <IonText color="success">
                      $
                      {data
                        .reduce((a, b) => a + b.amount * b.price, 0)
                        .toFixed(2)}
                    </IonText>
                  </IonBadge>
                  <IonText
                    style={{
                      fontSize: "11px",
                      margin: "auto",
                      padding: "20%",
                      textAlign: "center",
                      color: "#666",
                    }}
                  >
                    {(
                      (data.reduce((a, b) => a + b.amount * b.price, 0) * 100) /
                      stats.desc.usd_value
                    ).toFixed(2)}
                    %
                  </IonText>
                </IonCol>
              </IonRow>
            </IonCardHeader>
            <IonCardContent>
              {itemHeader}
              {data
                .sort((a, b) => b.price * b.amount - a.price * a.amount)
                .filter((x) =>
                  hideSmallHoldings ? x.price * x.amount > 1 : true
                )
                .map(
                  ({
                    id,
                    amount,
                    balance,
                    chain,                    
                    credit_score,
                    logo_url,
                    price,
                    symbol,
                    time_at,
                  }) => (
                    <IonRow key={chain + symbol}>
                      <IonCol>
                        <IonChip>
                          <IonAvatar>
                            <IonImg src={logo_url} />
                          </IonAvatar>
                          <IonText>{symbol}</IonText>
                          <IonAvatar>
                            {chain}
                          </IonAvatar>
                        </IonChip>
                      </IonCol>
                      {!smol && <IonCol>${price.toFixed(3)}</IonCol>}
                      <IonCol>{amount.toFixed(3)}</IonCol>
                      <IonCol>
                        <IonText color="success">
                          ${(amount * price).toFixed(2)}
                        </IonText>
                      </IonCol>
                    </IonRow>
                  )
                )}
              <IonItem>
                <IonButtons slot="start">
                  {data.filter((x) => x.price * x.amount > 1).length} Small
                  Balances ({!hideSmallHoldings ? "shown" : "hidden"})
                </IonButtons>{" "}
                <IonText color="success">
                  $
                  {data
                    .filter((x) => x.price * x.amount < 1)
                    .reduce((a, x) => a + x.amount * x.price, 0)
                    .toFixed(2)}
                </IonText>
                <IonToggle
                  value={!hideSmallHoldings ? "checked" : undefined}
                  onIonChange={({ detail }) => {
                    setHideSmallHoldings(!detail.checked);
                  }}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>
        ))}
        {!projectItems[address] && <IonProgressBar type="indeterminate" />}
        {projectItems[address] &&
          projectItems[address].map(
            ({ id, logo_url, name, portfolio_item_list, site_url }, i) => (
              <IonCard key={i}>
                <IonItem color="light">
                  <IonCol size="2">
                    <IonAvatar style={{ height: 50, width: 50 }}>
                      <IonImg src={logo_url} />
                    </IonAvatar>
                  </IonCol>
                  <IonCol size="7" sizeXs="8">
                    <IonCardTitle>{name}</IonCardTitle>
                    <IonCardSubtitle>
                      <a href={site_url}>{site_url}</a>
                    </IonCardSubtitle>
                  </IonCol>
                  <IonCol size="3" sizeXs="">
                    <IonBadge color="success">
                      $
                      {portfolio_item_list
                        .reduce((a, b) => a + b.stats.net_usd_value, 0)
                        .toFixed(2)}
                    </IonBadge>
                    <IonText
                      style={{
                        fontSize: "11px",
                        margin: "auto",
                        padding: "20%",
                        textAlign: "center",
                        color: "#666",
                      }}
                    >
                      {(
                        (portfolio_item_list.reduce(
                          (a, b) => a + b.stats.net_usd_value,
                          0
                        ) *
                          100) /
                        stats.desc.usd_value
                      ).toFixed(2)}
                      %
                    </IonText>
                  </IonCol>
                </IonItem>
                {itemHeader}
                {portfolio_item_list.map(
                  (
                    {
                      asset_dict,
                      asset_token_list,
                      detail,
                      detail_types,
                      name,
                      pool,
                      proxy_detail,
                      stats,
                      update_at,
                    },
                    i
                  ) => (
                    <IonRow key={i}>
                      <IonCol>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="12">
                              <IonChip>
                                {asset_token_list.map(
                                  (
                                    {
                                      logo_url,
                                      amount,
                                      chain,
                                      name,
                                      price,
                                      display_symbol,
                                      id,
                                    },
                                    i
                                  ) => (
                                    <IonAvatar key={i}>
                                      <IonImg
                                        src={logo_url || getBackupLogo(id)}
                                      />
                                    </IonAvatar>
                                  )
                                )}
                              </IonChip>
                            </IonCol>
                            <IonRow />
                            {name + " "}
                            <IonRow>
                              <IonText>
                                {asset_token_list
                                  .map((x) => x.optimized_symbol)
                                  .join(" & ")}
                              </IonText>
                            </IonRow>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      {!smol && (
                        <IonCol>
                          <IonGrid>
                            {asset_token_list.map(({ price }, i) => (
                              <IonRow key={i + "price"}>
                                <IonText>${price.toFixed(5)}</IonText>
                              </IonRow>
                            ))}
                          </IonGrid>
                        </IonCol>
                      )}
                      <IonCol>
                        <IonGrid>
                          {asset_token_list.map(
                            ({ amount, optimized_symbol, id }, i) => (
                              <IonRow
                                key={optimized_symbol + amount.toFixed(0)}
                              >
                                <IonText>
                                  {optimized_symbol + ": "}
                                  {amount.toFixed(3)}
                                </IonText>
                              </IonRow>
                            )
                          )}
                        </IonGrid>
                      </IonCol>
                      <IonCol>
                        <IonText color={"success"}>
                          ${stats.net_usd_value.toFixed(2)}
                        </IonText>
                      </IonCol>
                    </IonRow>
                  )
                )}
              </IonCard>
            )
          )}
      </IonContent>
    </IonPage>
  );
};
