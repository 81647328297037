/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
import { IonButton } from "@ionic/react";
import { BigNumber } from "ethers";
import { useNotification } from "../hooks/useNotifications";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { useEthers, useSigner } from "@usedapp/core";
import { useERC20 } from "../hooks/useERC20";
import React from "react";
import { formatUnits } from "ethers/lib/utils";
import { appConfig } from "../App";
export const ApproveERC20Button: React.FC<{
  chainId:number;
  contract: string;
  amount: BigNumber;
  operator: string;
  decimals: number;
}> = ({ contract, operator, amount, decimals,chainId }) => {
  const { account } = useEthers();
  const signer = useSigner();
  const { fetchApproval,getApproval,getInstance } = useERC20();
  const { post: postTx } = useTransactionModal();
  const { post } = useNotification();
  const allowance = getApproval(chainId,contract,operator,account);
  return (
    <>
      {signer&&allowance.lt(amount) && (
        <IonButton expand="full"
          onClick={() => {
            getInstance(chainId,contract,signer)
              .approve(operator, amount)
              .catch((message) => {
                post({ color: "danger", message: message.reason });
              })
              .then((tx) =>
                tx && postTx(signer,tx, "Approve", () => {
                  fetchApproval(chainId,contract, operator,account!);
                })
              );
          }}
        >
          Approve {formatUnits(amount, decimals)}
        </IonButton>
      )}
    </>
  );
};
