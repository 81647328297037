/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
} from "@ionic/react";
import {
  getMessagesBySrcTxHash,
  waitForMessageReceived,
} from "@layerzerolabs/scan-client";
import {
  Arbitrum,
  ArbitrumNova,
  Avalanche,
  BNB,
  Base,
  Canto,
  Celo,
  Fantom,
  FantomTestnet,
  Mainnet,
  Optimism,
  Polygon,
  useEthers,
  useSigner,
} from "@usedapp/core";
import { BigNumber, FixedNumber, constants, ethers } from "ethers";
import { formatEther, getAddress, hexZeroPad } from "ethers/lib/utils";
import {
  arrowForwardOutline,
  closeOutline,
  swapHorizontal,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { explorers, rpc } from "../App";
import { AppHeader } from "../components/AppHeader";
import { AppLayout } from "../components/AppLayout";
import { ApproveERC20Button } from "../components/ApproveERC20Button";
import { BridgeChainSelector } from "../components/BridgeChainSelector";
import {
  ChainIcon,
  ChainSelector,
  nativeCurrency,
} from "../components/ChainSelector";
import { supported, useChainId } from "../hooks/useChainId";
import { useERC20 } from "../hooks/useERC20";
import { useNotification } from "../hooks/useNotifications";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { Core, Scroll, Shimmer } from "../shimmer";
import { OFTV2Proxy__factory } from "../types/ethers-contracts";
import { ICommonOFT } from "../types/ethers-contracts/OFTV2Proxy";

const lzChainIds = {
  [Fantom.chainId]: 112,
  [Canto.chainId]: 159,
  [ArbitrumNova.chainId]: 175,
  [Arbitrum.chainId]: 110,
  [Polygon.chainId]: 109,
  [Avalanche.chainId]: 106,
  [BNB.chainId]: 102,
  [Mainnet.chainId]: 101,
  [Celo.chainId]: 125,
  [Shimmer.chainId]: 230,
  [Optimism.chainId]: 111,
  [FantomTestnet.chainId]: 10112,
  [Base.chainId]: 184,
  [Core.chainId]: 153,
  [Scroll.chainId]: 214,
};
const getLZChainId = (chainId: number) => {
  const entry = lzChainIds[chainId];
  if (!entry) {
    throw "Unsupported chain ID";
  }
  return entry;
};
const getEVMChainId = (chainId: number) => {
  const result = Object.entries(lzChainIds).find(
    ([, lzChainId]) => chainId === lzChainId
  );
  const response = result ? parseInt(result[0]) : -1;
  if (response != -1) {
    return response;
  } else {
    alert("error");
    return -1;
  }
};
const getBridgedAsset = (chainId: number, address: string) => {
  const result = Object.entries(lzTokenAddresses).find(
    ([, { addresses, proxies }]) =>
      proxies[chainId].toLowerCase() === address.toLowerCase() ||
      addresses[chainId].toLowerCase() === address.toLowerCase()
  );
  return result ? result[1] : undefined;
};

const lzTokenAddresses: Record<
  string,
  {
    ticker: string;
    name: string;
    icon: string;
    adapterParams: (account: string, chainId: number) => string;
    proxies: Record<number, string>;
    addresses: Record<string, string>;
  }
> = {
  // Potion: {
  //   name: "Potion",
  //   adapterParams:()=>"0x",
  //   icon: "https://magepunks.xyz/assets/images/potion-icon.png",
  //   addresses: { [250]: "0x3edA36088b931098e8E472748840b3dF78268c72" },
  //   proxies: {
  //     [Fantom.chainId]: "0x8ab16d7b7d7b36b458b8a47ef1f63ada2a84d499",
  //     [Arbitrum.chainId]: "0x89CA18732Db4A24E175Dd1c79CD06245265Cc42a",
  //   },
  // },
  SURV: {
    ticker: "SURV",
    name: "SurvToken",
    adapterParams: (address, chainId) => {
      const adapterParams = ethers.utils.solidityPack(
        ["uint16", "uint256"],
        [1, 200000]
      );
      if (chainId === Shimmer.chainId) return adapterParams;
      else return "0x";
    },
    icon: "/assets//survToken-surv-logo.jpeg",
    proxies: {
      [Arbitrum.chainId]: "0x2B6A85CD35D15691357eea61d88cB3f401A92FC3",
      [Fantom.chainId]: "0x2B6A85CD35D15691357eea61d88cB3f401A92FC3",
      [Avalanche.chainId]: "0x2B6A85CD35D15691357eea61d88cB3f401A92FC3",
      [Base.chainId]: "0x2B6A85CD35D15691357eea61d88cB3f401A92FC3",
      [Shimmer.chainId]: "0x2B6A85CD35D15691357eea61d88cB3f401A92FC3",
      [Scroll.chainId]: "0x2B6A85CD35D15691357eea61d88cB3f401A92FC3",
      [Core.chainId]: "0x0991ccb55822efb46ce15b31f9998d59dbd1390e",
    },
    addresses: {
      [Fantom.chainId]: "0x5d9EaFC54567F34164A269Ba6C099068df6ef651",
    },
  },
  // MIM: {
  //   ticker: "MIM",
  //   name: "Magic Internet Money",
  //   adapterParams: (account: string) =>
  //     solidityPack(
  //       ["uint16", "uint", "uint", "address"],
  //       [2, 200000, 0, account]
  //     ),
  //   icon: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png?1624979612",
  //   proxies: {
  //     [Arbitrum.chainId]: "0x957a8af7894e76e16db17c2a913496a4e60b7090",
  //     [Fantom.chainId]: "0xc5c01568a3B5d8c203964049615401Aaf0783191",
  //     [Avalanche.chainId]: "0xB3a66127cCB143bFB01D3AECd3cE9D17381B130d",
  //   },
  //   addresses: {
  //     [Fantom.chainId]: "0x82f0b8b456c1a451378467398982d4834b6829c1",
  //     [Arbitrum.chainId]: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
  //     [Avalanche.chainId]: "0x130966628846BFd36ff31a822705796e8cb8C18D",
  //   },
  // },
};

const lzStatusColor: Record<string, string> = {
  INFLIGHT: "primary",
  PENDING: "warning",
  DELIVERED: "success",
  FAILED: "danger",
};
export const BridgePage: React.FC = () => {
  const { chainId, bridgeChainId } = useChainId();
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0));
  const { account, switchNetwork } = useEthers();
  const signer = useSigner();
  const [token] = useState<string>("SURV");
  const [estimatedGasByChainId, setEstimatedGas] = useState<
    Record<number, BigNumber>
  >({});
  const estimatedGas = estimatedGasByChainId[chainId] || BigNumber.from(0);
  const tokenInfo = lzTokenAddresses[token];
  const tokenAddress =
    tokenInfo.addresses[chainId] || tokenInfo.proxies[chainId];
  const { post: postNotif, show } = useNotification();
  const { post: postTx } = useTransactionModal();
  const { getBalance, observe } = useERC20();
  const balance = getBalance(chainId, tokenAddress, account);
  const proxyAddress = lzTokenAddresses[token].proxies[chainId];
  useEffect(() => {
    account &&
      signer &&
      chainId &&
      tokenAddress &&
      observe(chainId, tokenAddress, account, signer);
  }, [chainId, tokenAddress, account, signer]);
  useEffect(() => {
    if (!bridgeChainId || !chainId || !account) {
      return;
    }
    if (!supported.includes(chainId)) {
      return;
    }

    const provider = new ethers.providers.JsonRpcProvider(rpc[chainId]);
    const proxy = OFTV2Proxy__factory.connect(
      lzTokenAddresses[token].proxies[chainId],
      provider
    );

    const accountBytes = hexZeroPad(account, 32);
    console.log(getLZChainId(bridgeChainId));
    console.log(bridgeChainId);
    console.log("Estimating Fee", proxy);
    proxy
      .estimateSendFee(
        getLZChainId(bridgeChainId),
        accountBytes,
        BigNumber.from(1),
        false,
        tokenInfo.adapterParams(account, chainId)
      )
      .then(({ nativeFee }) => {
        console.log(bridgeChainId, nativeFee.toString());
        setEstimatedGas((x) => ({ ...x, [chainId]: nativeFee }));
      })
      .catch((e) => {
        console.log(e, "ERROr");
      });
  }, [bridgeChainId, chainId, account, amount]);
  const [messages, setMessages] = useState<
    {
      created: string;
      dstChainId: number;
      dstTxError: string;
      dstTxHash: string;
      dstUaAddress: string;
      srcBlockHash: string;
      srcBlockNumber: string;
      srcChainId: number;
      srcTxHash: string;
      srcUaAddress: string;
      srcUaNonce: number;
      status: string;
      updated: number;
    }[]
  >([]);
  if (!supported.includes(chainId)) {
    return (
      <IonPage>
        <AppHeader title="Surveyor DAO"></AppHeader>
      </IonPage>
    );
  }
  return (
    <IonPage>
      <AppHeader title={"Bridge"} />
      <IonContent>
        <AppLayout>
          <IonCard>
            <IonCardHeader>
              <IonItem>
                <IonButtons slot="start">
                  <IonAvatar></IonAvatar>
                  <IonTitle color="tertiary">Bridge</IonTitle>
                  <IonChip>{token} Token</IonChip>
                </IonButtons>
                {/* <IonSelect
                  disabled
                  interface="popover"
                  value={token}
                  onBlur={({ currentTarget }) => {
                    if (token !== currentTarget.value) {
                      setToken(currentTarget.value!);
                    }
                  }}
                  onIonChange={({ detail }) => {
                    setToken(detail.value!);
                  }}
                >
                  {Object.keys(lzTokenAddresses).map((option, i) => (
                    <IonSelectOption key={i} value={option}>
                      {option}
                    </IonSelectOption>
                  ))}
                </IonSelect> */}
              </IonItem>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol size={"5"} className="ion-text-center">
                    <IonCardSubtitle>Origin Chain</IonCardSubtitle>
                    <ChainSelector hideConnect hideBalance account={account} />
                  </IonCol>
                  <IonCol size="2">
                    <IonRow>&nbsp;</IonRow>
                    <IonIcon
                      onClick={() => {
                        switchNetwork(bridgeChainId);
                      }}
                      size="large"
                      color="tertiary"
                      src={swapHorizontal}
                    />
                  </IonCol>
                  <IonCol size="5" className="ion-text-center">
                    <IonCardSubtitle>Target Chain</IonCardSubtitle>
                    <BridgeChainSelector />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonItem color="light">
                <IonButtons slot="start">
                  <IonButton>
                    <IonImg
                      style={{
                        borderRadius: "50px",
                        overflow: "hidden",
                        height: "30px",
                        width: 30,
                      }}
                      src={tokenInfo.icon}
                    />
                  </IonButton>
                </IonButtons>
                <IonInput
                  value={amount.eq(0) ? undefined : formatEther(amount)}
                  type="number"
                  debounce={500}
                  placeholder="Enter amount to bridge"
                  onIonChange={({ detail }) => {
                    setAmount(BigNumber.from(FixedNumber.from(detail.value!)));
                  }}
                />
                <IonButtons slot="end">
                  <IonButton
                    fill="outline"
                    color="tertiary"
                    onClick={() => {
                      setAmount(balance);
                    }}
                  >
                    {balance && parseFloat(formatEther(balance)).toFixed(3)}MAX
                  </IonButton>
                </IonButtons>
              </IonItem>
              {amount && amount.gt(0) && (
                <ApproveERC20Button
                  chainId={chainId}
                  amount={amount}
                  operator={proxyAddress}
                  contract={tokenAddress}
                  decimals={18}
                />
              )}
              <IonItem>
                <IonLabel color={"tertiary"}>Bridge Fee</IonLabel>
                {estimatedGas.gt(0) && (
                  <IonBadge color={"tertiary"}>
                    {parseFloat(formatEther(estimatedGas)).toFixed(4)}{" "}
                    {nativeCurrency[chainId]}
                  </IonBadge>
                )}
                {estimatedGas.eq(0) && (
                  <IonSpinner color="tertiary" name="bubbles" />
                )}
              </IonItem>

              {signer && account && (
                <IonButton
                  disabled={amount.eq(0)}
                  color="tertiary"
                  onClick={() => {
                    const proxy = OFTV2Proxy__factory.connect(
                      proxyAddress,
                      signer
                    );
                    console.log(bridgeChainId);
                    console.log("sending");
                    const callParams: ICommonOFT.LzCallParamsStruct = {
                      adapterParams: tokenInfo.adapterParams(account, chainId),
                      refundAddress: account,
                      zroPaymentAddress: constants.AddressZero,
                    };
                    console.log(callParams);
                    const value =
                      chainId === Shimmer.chainId
                        ? BigNumber.from("100000000000000000000")
                        : estimatedGas;
                    console.log(
                      chainId === Shimmer.chainId ? "SHIMMER" : "nope"
                    );
                    console.log({
                      addy: getAddress(account),
                      lzId: getLZChainId(bridgeChainId),
                      addyPadded: hexZeroPad(account, 32),
                      amount: amount,
                      callParams: callParams,

                      value: value,
                    });
                    const lzChainId = getLZChainId(bridgeChainId);
                    proxy
                      .sendFrom(
                        getAddress(account),
                        lzChainId,
                        hexZeroPad(account, 32),
                        amount,
                        callParams,
                        {
                          value,
                        }
                      )
                      .then((tx) => {
                        setMessages([
                          {
                            dstTxHash: "",
                            srcUaNonce: 0,
                            srcBlockNumber: "0",
                            dstTxError: "",
                            dstUaAddress: tokenInfo.proxies[bridgeChainId],
                            srcBlockHash: "",
                            srcUaAddress: proxyAddress,
                            srcTxHash: tx.hash,
                            status: "PENDING",
                            created: "",
                            updated: 0,
                            dstChainId: getLZChainId(bridgeChainId),
                            srcChainId: getLZChainId(chainId),
                          },
                        ]);
                        postTx(signer, tx, "Bridging " + token, () => {
                          setTimeout(() => {
                            getMessagesBySrcTxHash(
                              getLZChainId(chainId),
                              tx.hash
                            ).then((lzRes) => {
                              lzRes.messages &&
                                setMessages(lzRes.messages as any);
                            });
                          }, 5000);
                          setTimeout(() => {
                            getMessagesBySrcTxHash(
                              getLZChainId(chainId),
                              tx.hash
                            ).then((lzRes) => {
                              lzRes.messages &&
                                setMessages(lzRes.messages as any);
                            });
                          }, 10000);
                          setTimeout(() => {
                            getMessagesBySrcTxHash(
                              getLZChainId(chainId),
                              tx.hash
                            )
                              .then((lzRes) => {
                                setMessages(lzRes.messages as any);
                              })
                              .catch((e) => {
                                console.log(e);
                              });
                          }, 1000);
                          waitForMessageReceived(
                            getLZChainId(chainId),
                            tx.hash
                          ).then((message) => {
                            setMessages([message as any]);
                          });
                          postNotif({
                            message: "Bridge Confirmed",
                            color: "tertiary",
                            icon: "",
                          });
                        });
                      })
                      .catch((e) => {
                        console.error(e);
                        let reason=String(e.message);
                          const reasonPattern = /reason="([^"]*)"/;
                          const match = reason.match(reasonPattern);
                          
                          if (match && match[1]) {
                            reason = match[1];
                          }
                        
                        
                        postNotif({
                          message: reason,
                          color: "danger",
                          icon: "",
                        });
                        show();
                      });
                  }}
                  expand="full"
                >
                  Bridge Token
                </IonButton>
              )}
              {/* {signer&&<IonButton onClick={()=>{
                const trustedRemote = solidityPack(
                  ['address','address'],
                  [tokenInfo.addresses[bridgeChainId], tokenInfo.addresses[chainId]]
              )
                  OFTV2Proxy__factory.connect(tokenInfo.addresses[chainId],signer).setTrustedRemote(getLZChainId(bridgeChainId),trustedRemote);
              }}>
                Set Trusted Remote
              </IonButton>} */}
            </IonCardContent>
            {messages.map((message, i) => {
              return (
                <IonItem key={i}>
                  <IonChip>
                    <IonAvatar>
                      <IonImg
                        src={
                          getBridgedAsset(
                            getEVMChainId(message.srcChainId),
                            message.srcUaAddress
                          )?.icon
                        }
                      />
                    </IonAvatar>
                    <IonText>
                      {
                        getBridgedAsset(
                          getEVMChainId(message.srcChainId),
                          message.srcUaAddress
                        )?.ticker
                      }
                    </IonText>
                  </IonChip>
                  <ChainIcon
                    onClick={() => {
                      window.open(
                        explorers[getEVMChainId(message.srcChainId)] +
                          "/tx/" +
                          message.srcTxHash
                      );
                    }}
                    chainId={getEVMChainId(message.srcChainId)}
                  />
                  <IonIcon icon={arrowForwardOutline} />
                  <ChainIcon
                    onClick={
                      message.dstChainId
                        ? () => {
                            window.open(
                              explorers[getEVMChainId(message.dstChainId)] +
                                "tx/" +
                                message.dstTxHash
                            );
                          }
                        : undefined
                    }
                    chainId={getEVMChainId(message.dstChainId)}
                  />
                  <IonButton fill="clear">
                    {message.status === "INFLIGHT" ||
                      (message.status === "PENDING" && (
                        <IonSpinner color="warning" name="bubbles" />
                      ))}
                  </IonButton>
                  <IonButtons slot="end">
                    <IonButton
                      target="_new"
                      disabled={message.status === "PENDING"}
                      href={"https://layerzeroscan.com/tx/" + message.srcTxHash}
                      color={lzStatusColor[message.status]}
                    >
                      {message.status}
                    </IonButton>
                    <IonButton
                      onClick={() => {
                        setMessages((x) =>
                          x.filter((y) => y.srcTxHash !== message.srcTxHash)
                        );
                      }}
                    >
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                </IonItem>
              );
            })}
            {(chainId === Core.chainId &&
              bridgeChainId !== Avalanche.chainId) ||
              (bridgeChainId === Core.chainId &&
                chainId !== Avalanche.chainId && (
                  <IonItem>
                    <IonText color="danger">
                      Core Bridging is currently only supported via Avalanche.
                      Bridge to avalanche before bridging to or from Core
                    </IonText>
                  </IonItem>
                ))}
          </IonCard>
        </AppLayout>
      </IonContent>
    </IonPage>
  );
};
