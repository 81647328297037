import {  useRef } from "react";
import { useNotification } from "../hooks/useNotifications";
import React from "react";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import {
  IonModal,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonToolbar,
} from "@ionic/react";

export const Notifications: React.FC = () => {
  // const { initialize } = useTGE();
  const { notifications, close,open ,hide} = useNotification();
  const modal = useRef<HTMLIonModalElement>(null);
  return (
    <>
      <IonModal
        ref={modal}
        isOpen={open}
        onWillDismiss={() => {
          hide();
        }}
      >
        <IonCard>
          <IonCardHeader color="tertiary">
            <IonToolbar color="tertiary">
              <IonTitle>Notifications</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  color="danger"
                  onClick={() => {
                    modal.current?.dismiss();
                  }}
                >
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {notifications.map(({ color, message, icon }, id) => (
                <IonRow style={{cursor:'pointer'}} key={id} onClick={()=>{
                  if(notifications.length===1){
                    hide();
                  }
                  close(id)
                  }}>
                                       <IonIcon color={color} icon={icon} />
                  <IonText color={color}>{message}</IonText>
<IonButtons slot='end'><IonButton color='danger'> <IonIcon icon={closeOutline}/></IonButton></IonButtons>
                </IonRow>
              ))}
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonModal>
    </>
  );
};
