import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonCardSubtitle,
  IonIcon,
  IonButton,
  IonImg,
  IonAvatar,
} from "@ionic/react";
import React from "react";
import { ConnectButton } from "./ConnectButton";
import { appConfig } from "../App";

export const AppHeader: React.FC<{ title: string; subTitle?: string }> = ({
  title,
  subTitle,
}) => {
  return (
    <IonHeader>
      <IonToolbar color="secondary">
        <IonButtons slot="start">
          <IonButton routerLink="/info" routerDirection="root" >
            <IonAvatar>
              <IonImg color="light" src={appConfig.icon} />
            </IonAvatar>
          </IonButton>
        </IonButtons>
        <IonTitle>
          {title}
          {subTitle && (
            <IonCardSubtitle color="dark">{subTitle}</IonCardSubtitle>
          )}
        </IonTitle>
        <IonButtons slot="end">
          <ConnectButton />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
