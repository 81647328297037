import { Chain } from "@usedapp/core";

export const Shimmer:Chain = {
    chainId:148,
    chainName:"Shimmer",
    getExplorerAddressLink:(address:string)=>`https://explorer.evm.shimmer.network/address/${address}`,
    getExplorerTransactionLink:(hash:string)=>`https://explorer.evm.shimmer.network/tx/${hash}`,
    isLocalChain:false,
    isTestChain:false,
    blockExplorerUrl:"https://explorer.evm.shimmer.network/",
    rpcUrl:"https://json-rpc.evm.shimmer.network",
    multicallAddress:"0x32f20190540CCc55E137928c11d70d24daf52995",
    multicall2Address:"0x32f20190540CCc55E137928c11d70d24daf52995"
  }
  
export const Scroll: Chain = {
  chainId: 534352,
  chainName: 'SCROLL',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  rpcUrl: 'https://rpc.scroll.io',
  nativeCurrency: {
    name: 'Scroll',
    symbol: 'SCROLL',
    decimals: 18,
  },
  getExplorerAddressLink:(address:string)=>`https://scrollscan/address/${address}`,
  getExplorerTransactionLink:(hash:string)=>`https://scrollscan/tx/${hash}`,

}
export const Core:Chain = {
  chainId:1116,
  chainName:"Core",
  getExplorerAddressLink:(address:string)=>`https://scan.coredao.org/address/${address}`,
  getExplorerTransactionLink:(hash:string)=>`https://scan.coredao.org/tx/${hash}`,
  isLocalChain:false,
  isTestChain:false,
  blockExplorerUrl:"https://scan.coredao.org",
  rpcUrl:"https://rpc.coredao.org/",
  multicallAddress:"0xcA11bde05977b3631167028862bE2a173976CA11",
  multicall2Address:"0xcA11bde05977b3631167028862bE2a173976CA11"
}
