import { create } from 'zustand'

export interface ToastNotification {
  message: string
  icon?: string
  color: 'success' | 'danger' | 'primary' | 'tertiary' | 'medium'
}

export type NotificationTemplate = {
  hide: () => void
  show: () => void
  open: boolean
  notifications: ToastNotification[]
  post: (notification: ToastNotification) => void
  close: (id: number) => void
}

export const useNotification = create<NotificationTemplate>((set, store) => ({
  hide:()=>{
    set({open:false})
  },
  show:()=>{
    set({open:true})
  },
  open:false,
  notifications: [],
  close: (i) => {
    const notifications=store().notifications    
    notifications.splice(i,i+1) 
    set({ notifications})
  },
  post: (notif) => {
    set({ notifications: [...store().notifications, notif] })
  },
}))
