import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonIcon,
  IonItem,
  IonModal,
  IonSpinner,
  IonText,
} from "@ionic/react";
import {
  Arbitrum,
  ArbitrumGoerli,
  Avalanche,
  Base,
  Chain,
  DEFAULT_SUPPORTED_CHAINS,
  Fantom,
  FantomTestnet,
  Goerli,
  Mainnet,
  useBlockNumber,
  useEtherBalance,
  useEthers,
  useUpdateConfig,
} from "@usedapp/core";
import { formatEther } from "ethers/lib/utils";
import { closeOutline, cubeOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { dappConfig, supportedNetworks } from "../App";
import { useChainId } from "../hooks/useChainId";
import { Core, Scroll, Shimmer } from "../shimmer";

export const icons: Record<number, string> = {
  [Fantom.chainId]: "/assets/fantom-ftm-logo.svg",
  [FantomTestnet.chainId]: "/assets/fantom-ftm-logo.svg",
  [Mainnet.chainId]: "/assets/ethereum-eth-logo.svg",
  [Goerli.chainId]: "/assets/ethereum-eth-logo.svg",
  [Arbitrum.chainId]: "/assets/arbitrum-arb-logo.svg",
  [ArbitrumGoerli.chainId]: "/assets/arbitrum-arb-logo.svg",
  [Avalanche.chainId]: "/assets/avalanche-avax-logo.svg",
  [Base.chainId]: "/assets/base-logo.svg",
  [Shimmer.chainId]: "https://shimmer.network/assets/logo.svg",
  [Scroll.chainId]:"/assets/scroll.svg",
  [Core.chainId]: "https://cryptologos.cc/logos/core-dao-core-logo.svg",
};
export const names: Record<number, string> = {
  [Fantom.chainId]: "Fantom",
  [FantomTestnet.chainId]: "Fantom Testnet",
  [Goerli.chainId]: "Goerli",
  [Arbitrum.chainId]: Arbitrum.chainName,
  [Avalanche.chainId]: Avalanche.chainName,
  [Base.chainId]: Base.chainName,
  [Shimmer.chainId]: Shimmer.chainName,
  [Core.chainId]: Core.chainName,
  [Scroll.chainId]: Scroll.chainName,
};
export const nativeCurrency: Record<number, string> = {
  [Fantom.chainId]: "FTM",
  [Base.chainId]:"ETH",
  [FantomTestnet.chainId]: "TFTM",
  [Goerli.chainId]: "GETH",
  [Arbitrum.chainId]: "ETH",
  [Avalanche.chainId]: "AVAX",
  [Shimmer.chainId]: "SMR",
  [Core.chainId]: "Core",
  [Scroll.chainId]: "ETH",
};

export const ChainIcon: React.FC<{ chainId: number; onClick?: () => void }> = ({
  chainId,
  onClick,
}) => {
  const icon = chainId && icons[chainId];
  return (
    <>
      {icon && (
        <IonIcon
          style={{
            cursor: typeof onClick === "undefined" ? undefined : "pointer",
          }}
          onClick={onClick}
          icon={icon}
        />
      )}
    </>
  );
};

export const ChainName: React.FC = () => {
  const { chainId } = useChainId();
  return (
    <>
      {chainId && dappConfig.networks && dappConfig.networks[chainId].chainId}
    </>
  );
};

export const ChainSelector: React.FC<{
  account?: string;
  hideBalance?: boolean;
  hideConnect?: boolean;
}> = ({ account, hideBalance = false, hideConnect = false }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { setChainId } = useChainId();
  const { chainId, switchNetwork, activateBrowserWallet } = useEthers();
  const icon = chainId && icons[chainId];
  const update = useUpdateConfig();
  const balance = useEtherBalance(account);
  const [targetChain, setTargetChain] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const block = useBlockNumber();

  const defaultChain = [...DEFAULT_SUPPORTED_CHAINS, Scroll, Shimmer, Core].find(
    (x) => x.chainId === chainId
  );

  useEffect(() => {
    chainId && setChainId(chainId);
    if (
      typeof chainId !== "undefined" &&
      chainId !== 0 &&
      !supportedNetworks.includes(chainId)
    ) {
      setOpen(true);
    }
  }, [chainId, setChainId]);

  useEffect(() => {
    if (targetChain === chainId) {
      modal.current?.dismiss();
      setTargetChain(null);
      setIsLoading(false);
      setError(null);
    }
    if (chainId && supportedNetworks.includes(chainId)) {
      modal.current?.dismiss();
      update({ ...dappConfig, readOnlyChainId: chainId });
    }
  }, [targetChain, chainId, update]);

  const handleNetworkSwitch = async (chain: Chain) => {
    if (isLoading) return; // Prevent multiple switch attempts

    setIsLoading(true);
    setError(null);
    setTargetChain(chain.chainId);

    try {
      await switchNetwork(chain.chainId);
      await activateBrowserWallet();
    } catch (err) {
      console.error("Error switching network:", err);
      setError("Failed to switch network. Please try again.");
      setTargetChain(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonChip
      onClick={() => {
        if (account) {
          setOpen(true);
        }
      }}
    >
      {!hideBalance && (
        <IonText color="dark">
          {balance && parseFloat(formatEther(balance)).toFixed(3)}
        </IonText>
      )}
      {icon && targetChain === null ? <IonIcon icon={icon} /> : <IonSpinner />}
      <IonModal
        ref={modal}
        isOpen={open}
        backdropDismiss={
          chainId != 0 &&
          typeof chainId !== "undefined" &&
          supportedNetworks.includes(chainId)
        }
        onWillDismiss={() => {
          setOpen(false);
        }}
      >
        <IonCard>
          <IonCardHeader color={"primary"}>
            <IonItem color="primary" lines="none">
              <IonCardTitle>Networks:</IonCardTitle>
              <IonButtons slot="end">
                <IonButton
                  fill="clear"
                  onClick={() => {
                    modal.current?.dismiss();
                  }}
                >
                  <IonIcon icon={closeOutline} color="danger" />
                </IonButton>
              </IonButtons>
            </IonItem>
          </IonCardHeader>
          <IonCardContent>
            {dappConfig.networks
              ?.filter((x) => supportedNetworks.includes(x.chainId))
              .map((chain) => (
                <IonItem
                  href="javascript:void(0)"
                  onClick={() => handleNetworkSwitch(chain)}
                  key={chain.chainId}
                  disabled={isLoading}
                >
                  <IonButtons slot="start">
                    <IonIcon icon={icons[chain.chainId]} />
                    {chain.chainId}
                  </IonButtons>
                  {chain.chainName}
                  {chainId === chain.chainId && (
                    <IonChip color={"primary"}>
                      <IonIcon icon={cubeOutline} />
                      <IonText>{block}</IonText>
                    </IonChip>
                  )}
                  {targetChain === chain.chainId && isLoading && (
                    <IonSpinner name="dots" />
                  )}
                </IonItem>
              ))}

            {chainId && !supportedNetworks.includes(chainId) && (
              <IonItem href="#" key={chainId}>
                <IonText color="warning">
                  Not supported on {defaultChain && defaultChain.chainName}
                </IonText>
                <IonButtons slot="start">
                  <IonIcon icon={icons[chainId]} />
                  {chainId}
                </IonButtons>
                {defaultChain && (
                  <IonChip color={"danger"}>
                    <IonIcon icon={cubeOutline} />
                    <IonText>{block}</IonText>
                  </IonChip>
                )}
              </IonItem>
            )}

            {error && (
              <IonItem>
                <IonText color="danger">{error}</IonText>
              </IonItem>
            )}
          </IonCardContent>
        </IonCard>
      </IonModal>
      {!account && !hideConnect && (
        <IonButton
          fill="clear"
          onClick={() => {
            activateBrowserWallet();
          }}
        >
          Connect
        </IonButton>
      )}
      <IonText>
        {" "}
        {!hideBalance && account}
        {hideBalance && chainId && names[chainId]}
      </IonText>
    </IonChip>
  );
};