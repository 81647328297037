import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonProgressBar,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { checkmarkOutline, closeOutline, pulseOutline } from "ionicons/icons";
import React, { useRef } from "react";
import { explorers } from "../App";
import { useChainId } from "../hooks/useChainId";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { ChainIcon } from "./ChainSelector";
import { Notifications } from "./Notifications";

export const Modals: React.FC = () => {
  // const { initialize } = useTGE();
  const { transactions, hide, open, clear, confirmations } =
    useTransactionModal();
  const modal = useRef<HTMLIonModalElement>(null);
  const { chainId } = useChainId();
  const blockExplorer = chainId && explorers[chainId];
  return (
    <>
      <Notifications />
      <IonModal
        ref={modal}
        isOpen={open}
        onWillDismiss={() => {
          hide();
        }}
      >
        <IonCard>
          <IonCardHeader color="tertiary">
            <IonToolbar color="tertiary">
              <IonTitle>Transactions</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  color="danger"
                  onClick={() => {
                    modal.current?.dismiss();
                  }}
                >
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCardHeader>
          <IonCardContent>
              {transactions.map(({ name, transaction }) => (
                <IonList  key={transaction.hash}>
                <IonItem >
                  <IonButtons slot="start">
                    <IonButton
                      href={blockExplorer + "tx/" + transaction?.hash}
                      target="_new"
                    >
                      {confirmations[transaction.hash] && (
                        <IonIcon icon={checkmarkOutline} color="success" />
                      )}
                      {!confirmations[transaction.hash] && (
                        <IonIcon icon={pulseOutline} color="primary" />
                      )}
                      <IonLabel>{name}</IonLabel>
                      <ChainIcon chainId={transaction.chainId}/>
                    </IonButton>
                  </IonButtons>
                  {!confirmations[transaction.hash] && (
                    <IonProgressBar color={"primary"} type={"indeterminate"} />
                  )}
                  {confirmations[transaction.hash] > 1 && (
                    <IonProgressBar
                      color={"favorite"}
                      value={confirmations[transaction.hash] / 3}
                      type={"determinate"}
                    />
                  )                      
                  
}
                  <IonButtons slot="end">
                    <IonButton
                      onClick={() => {
                        if (transactions.length == 1) {
                          modal.current?.dismiss();
                        }
                        clear(transaction.hash);
                      }}
                      color="danger"
                    >
                      
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                </IonItem>
                </IonList>
              ))}
          </IonCardContent>
        </IonCard>
      </IonModal>
    </>
  );
};
