import { Avalanche, Base, Fantom } from "@usedapp/core";
import { create } from "zustand";
import { Core, Scroll, Shimmer } from "../shimmer";
export const supported=[Fantom.chainId,Avalanche.chainId,Base.chainId,Shimmer.chainId,Core.chainId,Scroll.chainId];
export type ChainIDTemplate = {
    chainId: number;
    supported: number[];
    bridgeChainId:number,
    setChainId:(chainId:number)=>void
    setBridgeChainId:(bridgeChainId:number)=>void
};

export const useChainId = create<ChainIDTemplate>((set, store) => ({
    chainId:0,
    bridgeChainId:0,
    supported,
    setChainId:(chainId:number)=>{
        if(store().bridgeChainId===chainId||store().bridgeChainId===0){
            const bridgeChainId = store().supported.filter(x=>x!==chainId)[0]
            set({...store(),bridgeChainId})
        }
        set({...store(),chainId})
    },
    setBridgeChainId:(bridgeChainId:number)=>{
        if(store().chainId===bridgeChainId){
            const chainId = store().supported.filter(x=>x!==bridgeChainId)[0]
            set({...store(),chainId})
        }
        set({...store(),bridgeChainId})
    }
}
));
