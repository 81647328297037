import {
    IonAvatar,
    IonButton,
  IonCard,
  IonChip,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonList,
  IonListHeader,
  IonPage,
  IonText,
} from "@ionic/react";
import React from "react";
import { AppHeader } from "../components/AppHeader";
import { useEthers } from "@usedapp/core";
import { AppLayout } from "../components/AppLayout";
import snapshot from '@snapshot-labs/snapshot.js';
import { airplaneOutline, documentsOutline, home, logoDiscord, logoTwitter, paperPlane, statsChart } from "ionicons/icons";
import { ChainIcon } from "../components/ChainSelector";
const hub = 'https://hub.snapshot.org'; // or https://testnet.snapshot.org for testnet
const client = new snapshot.Client712(hub);

export const Governance: React.FC = () => {
  const { chainId } = useEthers();
  return (
    <IonPage>
      <AppHeader title={"Info"} />
      <IonContent>
        <AppLayout>
          <IonCard>
          <IonItem target="_new" href='https://twitter.com/SurveyorDAO'>
                <IonIcon color='primary' icon={logoTwitter}/> @SurveyorDAO
            </IonItem>
            <IonItem href='https://discord.gg/myADKSkqKm'>
                <IonIcon  color={'tertiary'} icon={logoDiscord}/> Community
            </IonItem>
           <IonItem target="_new" href='https://t.me/+OVTX7KjHMrNhMWRk'>
            <IonIcon  color={'primary'} icon={paperPlane}/>
                Telegram 
            </IonItem>
            <IonItem target="_new" href='https://surveyor-dao.gitbook.io/surveyor-dao/'>
            <IonIcon  color={'success'} icon={documentsOutline}/>
<IonText>
            Documentation
            </IonText>
            </IonItem>
<IonItemDivider/>
<IonList>
                <IonListHeader>
                NFTS
            </IonListHeader>
<IonItem href='https://nftkey.app/collections/surveyors/'>
<IonAvatar>
    <IonImg src={"https://storage.googleapis.com/cdn.nftkey.app/nft-collections/surveyors/0xc3ed17b635142626d471f80c828080bafd81eadd8eb05e6589a735bc49f420fb-thumb.png"}/>
 </IonAvatar>
 Fantom Surveyors 
    </IonItem>    
    <IonItem href='https://opensea.io/collection/ladysurveyors'>
    <IonAvatar>
    <IonImg src={"https://i.seadn.io/gae/xVXzjsnlviJ_plxlZq5YGvAiSJuS0IpBK-KYq-iIltLbuzr0e57YgO_FECQ09OraNlyvF-vrS_nCaLr9xqHc8kfzIVTdv2d3PUBz?auto=format&dpr=1&w=1000"}/>
 </IonAvatar>
Avax Lady Surveyors
    </IonItem>    
    </IonList>
<IonItem href='https://exchange.soulswap.finance/swap?outputCurrency=0x5d9eafc54567f34164a269ba6c099068df6ef651'>
<IonAvatar>
<IonImg src='https://pbs.twimg.com/profile_images/1637063503935848449/xgoa3A_O_400x400.jpg'/>
</IonAvatar>
Buy $SURV
</IonItem>


<IonItem href='https://exchange.soulswap.finance/defarms'>
    <IonChip><IonAvatar>
<IonImg src='https://pbs.twimg.com/profile_images/1637063503935848449/xgoa3A_O_400x400.jpg'/>
</IonAvatar>
<ChainIcon chainId={250}/>
</IonChip>
Farm FTM / SURV
    </IonItem>
    <IonItem href='https://survdao.com/'>
 https://survdao.com/
</IonItem>
<IonItem href='https://streams.survdao.com/'>
<IonIcon icon={airplaneOutline}/>
Airdrop Vesting 
</IonItem>

<IonItem href='https://dexscreener.com/fantom/0xf9062af9ef6492cbd68aed1739769d75e461602d
'>
    <IonIcon icon={statsChart}/>
    Chart
</IonItem>
          </IonCard>
        </AppLayout>
      </IonContent>
    </IonPage>
  );
};
