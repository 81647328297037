import {
  IonChip,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import React from "react";
import {  useEthers } from "@usedapp/core";
import { useTransactionModal } from "../hooks/useTransactionModal";
import { useNotification } from "../hooks/useNotifications";
import {  alertOutline } from "ionicons/icons";
import { ChainSelector } from "./ChainSelector";

export const AccountChip: React.FC<{ account: string }> = ({ account }) => (
  <IonChip color={"secondary"}>
    {account.slice(0, 6)}...
    {account.slice(account.length - 6, account.length)}
  </IonChip>
);

export const ConnectButton: React.FC = () => {
  const { account } =
    useEthers();
  const { pending, show } = useTransactionModal();
  const { notifications, show: showNotifs } = useNotification();
  return (
    <>
      
        <ChainSelector account={account} />
       
      {pending() > 0 && (
        <IonChip
          color="tertiary"
          onClick={() => {
            show();
          }}
        >
          <IonSpinner /> {pending()} tx
        </IonChip>
      )}
      {notifications.length > 0 && (
        <IonChip
          color={notifications[0].color}
          onClick={() => {
            showNotifs();
          }}
        >
          <IonIcon icon={alertOutline} /> {notifications.length}
        </IonChip>
      )}
    </>
  );
};
